<template>
  <div id="userBaseInfo">
    <van-nav-bar title="基本资料" :border="false" @click-left="goLastIndex" left-arrow>
    </van-nav-bar>
    <div class="baceInfoWrap">
      <div class="baceInfoPublic">
        <van-cell-group>
          <van-cell title="头像" size="large" is-link center>
            <van-uploader :after-read="avatarUpLoad">
              <div class="avatarUpLoad" v-if="avatarLoading">
                <img :src="avatarUrl" alt="">
              </div>
              <div class="avatarUpLoad" v-else>
                <van-loading type="spinner" />
              </div>
            </van-uploader>
          </van-cell>
          <van-popup v-model="picShow" closeable close-icon-position="top-right" position="bottom" :style="{ height: '30%' }">
          </van-popup>
        </van-cell-group>
      </div>
      <div class="baceInfoPublic">
        <van-cell-group>
          <van-cell title="姓名" :value="name" is-link @click="changeName" />
          <van-cell title="手机号（无接口）" :value="user_mobile" is-link />
          <!-- <van-cell-group>
          <van-cell title="我的等级" size="large" is-link />
        </van-cell-group> -->
          <van-cell title="性别" :value="sexType == 1 ? '男' :sexType == 2 ? '女':'完善性别'" is-link @click="sexShow = true" />
          <van-popup v-model="sexShow" position="bottom" :style="{ height: '50%' }">
            <tool-bar @cancel="sexShow = false" @confirm="sexShow = false" leftTitle="取消" rightTitle="确定" title="服务类型" />
            <van-radio-group v-model="sexType" checked-color="#FF816C">
              <van-cell title="男" clickable @click="setSex('1')">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
              <van-cell title="女" clickable @click="setSex('2')">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
            </van-radio-group>
          </van-popup>
          <van-cell title="紧急联系人" :value="mobile" is-link @click="changeMobile" />
          <van-popup v-model="mobileShow" position="bottom" :style="{ height: '50%' }">
            <div>
              <div>
                <!-- <van-nav-bar title="添加紧急联系人" :border="false" /> -->
                <tool-bar @cancel="mobileShow = false" @confirm="reviewMobile" leftTitle="取消" rightTitle="确定" title="添加紧急联系人" />
                <van-field v-model="mobile" type="tel" label="紧急联系人" placeholder="请输入手机号" style="margin-top: 20px" />
              </div>
            </div>
          </van-popup>
          <!-- 收货地址 -->
          <van-cell title="收货地址" :value="receipt_address" is-link @click="changeReceiveAdress" />
          <van-popup v-model="receiveAdressShow" position="bottom" :style="{ height: '60%' }" visible-item-count="2">
            <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @confirm="confirmReceiveAdress" />
          </van-popup>
          <van-field v-model="receipt_address_detail" rows="1" autosize label="详细收货地址" type="textarea" placeholder="请输入" />
          <!-- 所在地址 -->
          <van-cell title="所在地址" :value="address" is-link @click="changeAddress" />
          <van-popup v-model="addressShow" position="bottom" :style="{ height: '60%' }" visible-item-count="2">
            <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @confirm="confirmAdress" />
          </van-popup>
          <van-field v-model="address_detail" rows="1" autosize label="详细所在地址" type="textarea" placeholder="请输入" />
        </van-cell-group>
      </div>
    </div>
    <div class="positionBottom">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitBaseInfo">
        点击修改
      </van-button>
    </div>
    <div id="IsVendied" v-show="pageShow == 1" style="position:fixed; top: 0; left: 0; right: 0; bottom: 0;z-index:99" @click="goBaseInfo">
      <IsVendied />
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import add from "@/assets/area.js";
import IsVendied from "@/components/Mine/IsVendied.vue";
import ToolBar from "@/components/effect/ToolBar.vue";

export default {
  data() {
    return {
      radio: 1,
      picShow: false,
      sexShow: false,
      mobileShow: false,
      phoneShow: false,
      receiveAdressShow: false,
      addressShow: false,
      sexType: 1,
      userNowInfo: {},
      areaList: add,
      avatarId: "",
      avatarUrl: require("../../../../assets/images/touxiang.png"),
      name: "",
      mobile: "",
      user_mobile: "",
      receipt_address: "",
      receipt_address_province: "",
      receipt_address_city: "",
      receipt_address_county: "",
      receipt_address_detail: "",
      address: "",
      address_province: "",
      address_city: "",
      address_county: "",
      address_detail: "",
      avatarLoading: true,
      pageShow: -1,
    };
  },
  components: {
    TopBar,
    IsVendied,
    ToolBar,
  },
  created() {
    console.log(this.sexType);
    this.$http.get("/api/v2/worker/profile").then((res) => {
      this.userNowInfo = res.data;
      console.log(res, "基本资料");
      res.data.avatar
        ? ((this.avatarUrl = `${this.cdnHttp}${res.data.avatar}?imageView2/1/format/webp/w/110/h/110/q/90`),
          (this.avatarId = res.data.avatar))
        : "https://cdn.yishifu.com/image/20201118091623JNqFmdP7.png";
      this.name = res.data.name ? res.data.name : "实名认证";
      this.user_mobile = res.data.mobile;
      this.sexType = res.data.sex ? res.data.sex : "0";
      console.log(this.sexType);
      this.mobile = res.data.urgent_contact_mobile
        ? res.data.urgent_contact_mobile
        : "完善联系人";
      res.data.receipt_address
        ? ((this.receipt_address = res.data.receipt_address.cn),
          (this.receipt_address_province = res.data.receipt_address.code[0]),
          (this.receipt_address_city = res.data.receipt_address.code[1]),
          (this.receipt_address_county = res.data.receipt_address.code[2]))
        : "请完善地址";
      res.data.receipt_address_detail
        ? (this.receipt_address_detail = res.data.receipt_address_detail)
        : "请完善详细收货地址";
      res.data.full_address
        ? ((this.address = res.data.full_address.cn),
          (this.address_province = res.data.full_address.code[0]),
          (this.address_city = res.data.full_address.code[1]),
          (this.address_county = res.data.full_address.code[2]))
        : "请完善所在地址";
      res.data.address_detail
        ? (this.address_detail = res.data.address_detail)
        : "请完善所在详细地址";
      switch (res.data.certification) {
        case 1:
          this.name = res.data.name;
          break;
        case 2:
          this.name = "实名认证中";
          break;
        case 0:
          this.name = "去实名认证";
      }
    });
  },
  methods: {
    // 请求上传头像
    avatarUpLoad(e) {
      console.log(e);
      let imageFormData = new FormData();
      imageFormData.append("files", e.file);
      // console.log(e, "ss");
      this.avatarLoading = false;
      this.$http
        .post("/api/worker/uploadAvatar", imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.avatarId = res.data.result.url;
          this.avatarLoading = true;
          this.avatarUrl = `${this.cdnHttp}${res.data.result.url}?imageView2/1/format/webp/w/110/h/110/q/90`;
        });
    },
    confirmReceiveAdress(e) {
      console.log(e);
      this.receipt_address_province = e[0].code;
      this.receipt_address_city = e[1].code;
      this.receipt_address_county = e[2].code;
      this.receipt_address = e[0].name + "," + e[1].name + "," + e[2].name;
      this.receiveAdressShow = false;
    },
    confirmAdress(e) {
      this.address_province = e[0].code;
      this.address_city = e[1].code;
      this.address_county = e[2].code;
      this.address = e[0].name + "," + e[1].name + "," + e[2].name;
      this.addressShow = false;
    },
    changeName() {
      switch (this.userNowInfo.certification) {
        case 1:
          this.pageShow = 1;
          break;
        case 2:
          this.$toast("实名认证审核中");
          break;
        case 0:
          this.goSubPage("certification");
          break;
      }
    },
    changeMobile() {
      this.mobileShow = true;
    },
    changeReceiveAdress() {
      this.receiveAdressShow = true;
    },
    changeAddress() {
      this.addressShow = true;
    },
    reviewMobile() {
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$toast("请输入正确的手机号码");
      } else {
        this.mobileShow = false;
      }
    },
    close() {
      this.phoneShow = false;
    },
    changePhone() {
      console.log("无数据");
    },
    setSex(index) {
      this.sexType = index;
    },
    goLastIndex() {
      this.$router.go(-1);
    },
    goBaseInfo(e) {
      this.pageShow = e;
    },
    submitBaseInfo() {
      /*   if (this.avatarId == "") {
        this.$toast("请上传头像");
      } else if (this.sexType == 0) {
        this.$toast("请选择性别");
      } else if (this.mobile == "") {
        this.$toast("请完善紧急联系人");
      } else if (this.receipt_address_county == "") {
        this.$toast("请选择收货地址");
      } else if (this.receipt_address_detail == "") {
        this.$toast("请选择收货详细地址");
      } else if (this.address_county == "") {
        this.$toast("请选择所在地址");
      } else if (this.address_detail == "") {
        this.$toast("请选择所在详细地址");
      }  */
      this.$http
        .post("/api/worker/baseProfile", {
          sex: this.sexType,
          avatar: this.avatarId,
          urgent_contact_mobile: this.mobile,
          receipt_address_province: this.receipt_address_province,
          receipt_address_city: this.receipt_address_city,
          receipt_address_county: this.receipt_address_county,
          receipt_address_detail: this.receipt_address_detail,
          address_province: this.address_province,
          address_city: this.address_city,
          address_county: this.address_county,
          address_detail: this.address_detail,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$toast.success("修改成功");
            this.$router.go(0);
          }
        });
    },
  },
};
</script>
<style lang="less">
#userBaseInfo {
  .positionBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .baceInfoWrap {
    .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        font-size: 1.3rem;
      }
      .avatarUpLoad {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
    .tip {
      font-size: 1.4rem;
      text-align: center;
      padding: 1rem 0;
    }
    .baceInfoPublic {
      margin: 1rem 0;
    }
  }
  .upLoadImg {
    width: 100%;
    height: 5rem;
    background: darkcyan;
  }
  .urgentPhone {
    width: 40%;
    border: none;
    text-align: right;
  }
  .urgentCode {
    text-align: left;
    width: 40%;
    border: none;
  }
  .van-nav-bar__text {
    color: #9697b2;
  }
}
</style>