<template>
  <div id="isverified">
    <TopBar class="background">
      <template #leftInfo>
        <div style="white-space: nowrap">
          <svg t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24" @click="goBaseInfo">
            <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#ffffff"></path>
          </svg>
        </div>
      </template>
      <template #middleInfo> <span class="topTitle">实名认证</span> </template>
      <template #rightInfo> </template>
    </TopBar>
    <div class="isVendied">
      <img src="../../assets/images/card.png" alt="" />
    </div>
    <p class="idTips">您已实名认证</p>

    <div class="idCard">
      <van-cell title="真实姓名" size="large" value="冯师傅"> </van-cell>
      <van-cell title="身份证号" size="large" :value="userCard"> </van-cell>
      <van-cell title="证件审核" size="large" value="已审核"> </van-cell>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  data() {
    return {
      userCard: "342921********1155",
    };
  },
  methods: {
    goBaseInfo() {
      this.$emit("goBaseInfo", 2);
    },
  },
};
</script>
<style lang="less">
#isverified {
  background: @themeBacColor;
  height: 100vh;
  .idCard {
    .urgentPhone {
      width: 60%;
      border: none;
    }
  }
  .idTips {
    padding: 0 0 2rem 0;
    text-align: center;
    font-size: 1.4rem;
    color: #bc8686;
  }
  .isVendied {
    margin-top: 45px;
    padding: 3.6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60%;
    }
  }
}
</style>>